page.form = $('#form-sign-in');

$(document).ready(() => {
  sessionStorage.clear();

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      // Force a reload if the user has logged out.
      location.reload();
    }
  });

  let urlParams = new URLSearchParams(location.search);
  page.closeTab = urlParams.has('close-tab');

  let lastPathname = location.pathname.replace('/en', '');
  setInterval(() => {
    let newPathname = location.pathname.replace('/en', '');

    if (lastPathname != newPathname) {
      page.emit('url-changed', newPathname, lastPathname);

      lastPathname = newPathname;
    }
  });


  if (page.mode == 'sign-in') {
    page.form.input('username').val(Ingtech.cookie.username);

    $('#box-sign-in').show().removeClass('not-visible');
  } else if (page.mode == 'accounts') {
    page.showAccounts();
  } else if (page.mode == 'password-recovery') {
    $('#box-password-recovery').show().removeClass('not-visible');
  } else if (page.mode == 'reset-password') {

    let params = new URL(location.href).searchParams;

    $('#form-reset-password').input('username').val(params.get('u'));
    $('#form-reset-password').input('token').val(params.get('t'));

    $('#box-reset-password').show().removeClass('not-visible');
  }


  let keyPressed = false;
  $(window).keypress(e => {
    if (e.which == 13) {
      if (keyPressed) return;

      keyPressed = true;

      if (page.mode == 'sign-in') {
        page.tryConnect();
      } else if (page.mode == 'accounts') {
        if (e.shiftKey) {
          page.selectAccount(page.user.currentAccount);
        }
      } else if (page.mode == 'password-recovery') {
        page.requestPassword();
      } else if (page.mode == 'reset-password') {
        page.resetPassword();
      } else if (page.mode == 'notification') {
        page.changePage('/login');
      }
    }
  }).keyup(e => {
    if (e.which == 13) {
      keyPressed = false;
    }
  });


  $('#btn-sign-in').click(() => {
    page.tryConnect();
  });

  $('form').submit(e => {
    e.preventDefault();
  });

  $('#btn-password-recovery').click(() => {
    page.requestPassword();
  });

  $('#btn-reset-password').click(() => {
    page.resetPassword();
  });
});

page.waitResponse = false;

page.tryConnect = async function () {
  if (page.waitResponse) return;

  page.waitResponse = true;

  $('#btn-sign-in').prop('disabled', true);

  let data = util.formSerializeObject(page.form);

  await $('#box-sign-in').loadingAnim(async () => {
    try {
      let user = await $.post('/json/login', data);
      // $('#box-sign-in').addClass('not-visible');

      page.user = user;

      if (page.user.changePassword == 1) {
        page.mode = 'reset-password';
        page.changePage(`/login/reset-password?u=${page.user.username}&t=${page.user.resetToken}`);

        let params = new URL(location.href).searchParams;

        $('#form-reset-password').input('username').val(params.get('u'));
        $('#form-reset-password').input('token').val(params.get('t'));
      } else {
        page.mode = 'accounts';
        page.changePage('/login/accounts');
      }

    } catch (err) {
      util.notifyError(err);
    } finally {
      page.waitResponse = false;

      $('#btn-sign-in').prop('disabled', false);
    }
  }, 250, 'transparent');
};

page.showAccounts = async function () {
  let $list = $('#accounts-list');

  $list.empty();

  if (page.user) {
    const user = await $.get('/json/user');

    if (user.id != page.user.id) {
      page.mode = 'sign-in';
      page.changePage('/login');

      return;
    }

    let accountsByClient = Object.values(util.regroupByKey(page.user.accounts, 'clientId'))
      .sort((a, b) => a[0].clientName.localeCompare(b[0].clientName));

    for (let accounts of accountsByClient) {
      const activeAccounts = accounts.filter(account => account.status == 1);
      if (activeAccounts.length == 0) continue;

      let $item = $(`
        <div>
          <h2 class="title-account">
            ${activeAccounts[0].clientName}
            ${user.superuser == 1 && activeAccounts[0].parentName ? ` - ${activeAccounts[0].parentName}` : ''}
            ${activeAccounts[0].clientStatus == 0 ? ` - ${i18n.__INACTIVE}` : ''}
          </h2>
        </div>
      `);

      util.sortObjects(activeAccounts, 'role');
      for (let account of activeAccounts) {
        if (account.status == 0) continue;

        let icon = account.role == 'admin' ? 'main-graph-filled' : 'main-steering-wheel-filled';
        let $button = $(`<button class="btn btn-link btn-account" data-account="${account.id}"><i class="icon-${icon}"></i>${i18n['__' + account.role.toUpperCase()]}</button>`);

        $button.click(function () {
          // $(this).blur();
          page.selectAccount(account.id);
        });

        // if (page.user.currentAccount == account.id) {
        //   util.sleep(500).then(() => {
        //     $button.focus();
        //   });
        // }

        $item.append($button);
      }

      $list.append($item);
    }
  }

  $('#box-accounts').show().removeClass('not-visible');
};

page.selectAccount = async function (accountId) {
  if (page.waitResponse) return;

  page.waitResponse = true;

  await $("#box-accounts").loadingAnim(async () => {
    try {
      let result = await $.post('/json/login/account' + window.location.search, { accountId });

      Ingtech.cookie.username = page.user.username;
      Ingtech.cookie.currentAccount = result.accountId;

      await util.sleep(100);

      if (page.closeTab) {
        Ingtech.cookie.token = page.user.accessToken;
        window.close();
      } else {
        const anchor = location.hash;
        window.location = result.url + anchor;
      }
    } catch (err) {
      util.notifyError(err);

      page.changePage('/login');
    } finally {
      page.waitResponse = false;
    }
  }, 250, 'transparent');
};


page.requestPassword = async function () {
  if (page.waitResponse) return;

  page.waitResponse = true;

  $('#btn-password-recovery').prop('disabled', true);

  let data = util.formSerializeObject($('#form-password-recovery'));

  await $("#box-password-recovery").loadingAnim(async () => {
    try {
      let response = await $.post('/json/login/password-recovery', data);

      if (response.message) {
        let message = Object.values(response.message)[0];

        page.notify(i18n.__HOME.__RESET_EMAIL_SENT);
      } else {
        util.notify(response);

        page.changePage('/login');
      }
    } catch (err) {
      util.notifyError(err);
    } finally {
      page.waitResponse = false;

      $('#btn-password-recovery').prop('disabled', false);
    }
  }, 250, 'transparent');
};

page.resetPassword = async function () {
  if (page.waitResponse) return;

  page.waitResponse = true;

  $('#btn-reset-password').prop('disabled', true);

  let data = util.formSerializeObject($('#form-reset-password'));

  await $("#box-reset-password").loadingAnim(async () => {
    try {
      if (data.password.length < 6) {
        util.notify('PASSWORD_MIN_LENGTH', 'error');
      } else if (data.password != data.confirmation) {
        util.notify('PASSWORD_EQUAL_TO', 'error');
      } else {
        let response = await $.post('/reset-password', data);

        if (response.message) {
          let message = Object.values(response.message)[0];

          page.notify(message);
        } else {
          util.notify(response);

          page.changePage('/login');
        }
      }
    } catch (err) {
      util.notifyError(err);
    } finally {
      page.waitResponse = false;

      $('#btn-reset-password').prop('disabled', false);
    }
  }, 250, 'transparent');
};


page.on('url-changed', async (newUrl, oldUrl) => {
  $('.form-box').addClass('not-visible');
  await util.sleep(500);

  if (oldUrl == '/login/accounts') {
    page.user = null;
  } else if (oldUrl == '/login/password-recovery') {
  } else if (oldUrl == '/login/reset-password' || oldUrl == '/request-reset-password') {
  }

  $('.form-box').hide();

  if (newUrl == '/login') {
    page.mode = 'sign-in';

    location.reload();

    // $('#box-sign-in').show().removeClass('not-visible');
    // $('#box-sign-in').find('input').removeClass('big-style').addClass('big-style')

  } else if (newUrl == '/login/accounts') {
    page.mode = 'accounts';

    if (page.user) {
      page.showAccounts();
    } else {
      page.loadPage('/login');
    }
  } else if (newUrl == '/login/password-recovery') {
    page.mode = 'password-recovery';

    $('#form-password-recovery').input('username').val($('#form-sign-in').input('username').val());

    $('#box-password-recovery').show().removeClass('not-visible');
  } else if (newUrl == '/login/reset-password') {
    page.mode = 'reset-password';

    $('#box-reset-password').show().removeClass('not-visible');
  }
});

page.changePage = function (page, keepSearch = true) {
  let lang = language != 'fr' ? '/' + language : '';
  const anchor = location.hash;

  history.pushState(null, null, lang + page + (keepSearch ? location.search : '') + anchor);
};

page.loadPage = function (page) {
  let lang = language != 'fr' ? '/' + language : '';
  window.location = lang + page + location.search;
};

page.notify = async function (message) {
  $('.form-box').addClass('not-visible');

  page.mode = 'notification';

  await util.sleep(500);

  $('#message-box').text(message);
  $('#box-notification').show().removeClass('not-visible');
};